import { graphql } from "gatsby"
import React from "react"
import propTypes from "prop-types"

import { rhythm } from "../../utils/typography"
import Footer from "../../components/footer"
import Header from "../../components/header"
import Container from "../../components/container"
import HeroContainer from "../../components/containers/hero"
import IntegrationsList from "./integrations-list"
import IntegrationsShowcase from "./integrations-showcase"
import Layout from "../../layouts"
import { mediaQueries } from "../../utils/presets"
import SEO from "../../components/seo"
import Subtitle from "../../components/hero/subtitle"
import Title from "../../components/hero/title"
import { Button, ButtonGroup } from "../../components/core/button"

const styles = {
  title: {
    textAlign: `center`,
    marginLeft: `auto`,
    marginRight: `auto`,
    [mediaQueries.phablet]: {
      maxWidth: rhythm(31),
    },
  },
  subtitle: {
    textAlign: `center`,
    marginLeft: `auto`,
    marginRight: `auto`,
  },
}

const IntegrationsPage = ({
  data: { nav, contentfulPage: page },
  location: { pathname },
}) => (
  <Layout pathname={pathname}>
    <SEO contentfulPage={page} />
    <Header navItems={nav.edges} />

    <Container>
      <HeroContainer>
        <Title customStyles={styles.title}>{page.title}</Title>
        <Subtitle customStyles={styles.subtitle}>
          {page.subtitle.subtitle}
        </Subtitle>
      </HeroContainer>

      <IntegrationsShowcase />

      <IntegrationsList page={page} />

      <ButtonGroup>
        <Button
          to="https://www.gatsbyjs.org/plugins/?=source"
          buttonStyle="accent"
        >
          See Integrations Docs
        </Button>
      </ButtonGroup>
    </Container>

    <Footer navItems={nav.edges} />
  </Layout>
)

IntegrationsPage.propTypes = {
  data: propTypes.object.isRequired,
  location: propTypes.object.isRequired,
}

export default IntegrationsPage

export const pageQuery = graphql`
  query IntegrationsPage($id: String!) {
    ...MainNavigation
    contentfulPage(id: { eq: $id }) {
      name
      title
      subtitle {
        subtitle
      }
      backgroundColor
      ...SocialMediaImage
      sections {
        logoBoxes {
          id
          header
          description {
            description
          }
          logos {
            title
            fluid(maxWidth: 50) {
              srcSet
              src
              sizes
              aspectRatio
            }
          }
        }
      }
    }
  }
`
